import React from 'react';
import styles from "./helpneed.module.scss"
import {motion} from 'framer-motion'
import { HashLink } from 'react-router-hash-link';


function Helpneed ( ) {

   const boxAnimationSpan = {
      hidden: {
         y: 200,
         opacity: 0,
      },
      visible: custom => ({
         y: 0,
         opacity: 1,
         transition: { delay: custom * 0.2, duration: 1, ease: "easeOut" },
      })
   }
   const imgAnimationSpan = {
      hidden: { 
         x: -200,
         opacity: 0,
      },
      visible: {
         x: 0,
         opacity: 1,
         transition: { delay: 0.5, duration: 1, ease: "easeOut" },
      }
   }
   const textAnimationTxt = {
      hidden: {
         y: 100,
         opacity: 0,
      },
      visible: {
         y: 0,
         opacity: 1,
         transition: { delay: 1, duration: 1, ease: "easeOut" },
         ease: 'leaner',
      }
   }

   return (
      <motion.section
         initial="hidden"
         whileInView="visible"
         viewport={{once: true}}
         className={styles.root}
      >

         {/* скрываемый блок */}
         <div className={styles.help_podpis_txt}>
            <motion.p variants={textAnimationTxt}>Our foundation operates in Ukraine's Kherson region, adjacent to Crimea, with a mission to restore vineyards and wineries devastated by conflict. We are committed to rebuilding the heritage of viticulture by aiding in the recovery of damaged estates and supporting the families of vintners whose lives and livelihoods have been affected by missile strikes and other acts of destruction.</motion.p>
         </div>

         {/* - - кнопка доната */}
         <div className={styles.help_center}>
         <HashLink 
               to="/path#datails" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><div className={styles.help_but}>
               <p>donation</p>
            </div></HashLink>
         </div>

         {/* - - заголовок */}
         <div className={styles.help_span}>
            <p>Help Needed for Vineyards and Winemakers in Kherson, Ukraine</p>
         </div>

         {/* - - тело поста */}
         <div className={styles.help_post}>
            <motion.div className={styles.help_post_img} variants={imgAnimationSpan} >
               <img src="./img/fone/f_03.jpg" title="foto" alt="foto"></img>
            </motion.div>
            <div className={styles.help_span_visible}>
               <p>Help Needed for Vineyards and Winemakers in Kherson, Ukraine</p>
            </div>
            <div className={styles.help_post_blocks}>
               <motion.div className={styles.post_blocks_text} custom={4} variants={boxAnimationSpan}>
                  <p>Our region's economy has come to a near standstill, and the vineyards and winemakers of Kherson, Ukraine, have suffered immensely. </p>
               </motion.div>
               <motion.div className={styles.post_blocks_text} custom={5} variants={boxAnimationSpan}>
                  <p>The ongoing conflict has led to the loss of everything for many, as estates have been plundered and vineyards destroyed by military actions and missile strikes. </p>
               </motion.div>
               <motion.div className={styles.post_blocks_text} custom={6} variants={boxAnimationSpan}>
                  <p>Today, we are in dire need of agricultural equipment, especially tractors, to cultivate our vineyards and save the remaining plants.</p>
               </motion.div>
            </div>
         </div>



      </motion.section>
   )
}

export default Helpneed;