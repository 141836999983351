import React from 'react';
import styles from "./novapost.module.scss"


function Novapost ( ) {


   return (
      <section className={styles.root}>
         <div className={styles.nova_post} id="contact">

            <div className={styles.block_one}>
               <p>If you would like to send us a small package with a donation or any other form of assistance, you can use the logistics company "Nova Poshta", which has branches in the European Union countries. Your package will be delivered to our city, Kherson, Ukraine.</p>
            </div>

            <a href="https://novapost.com/" title="www.novapost.com" target="_blank" rel="noreferrer" >
               <div className={styles.block_but}>
                  <p>NOVA POST</p>
               </div>   
            </a>

            <div className={styles.block_two}>
               <div className={styles.block_two_postfirst}>
                  <div className={styles.postfirst_img}>
                     <img src="./img/icon/016.gif" title="map" alt="map"></img>
                     <p>indicate our details (recipient):</p>
                  </div>
                  <div className={styles.postfirst_txt}>
                     <p>Ward number 1 Kherson, logistics company Nova Poshta.</p>
                  </div>
               </div>
               <div className={styles.block_two_postsecond}>
                  <div className={styles.postsecond_img}>
                     <img src="./img/icon/016.gif" title="map" alt="map"></img>
                     <p>recipient of the parcel:</p>
                  </div>
                  <div className={styles.postsecond_txt}>
                     <p>Charitable Organization “Charitable Foundation” Invino Vera, tel. +380675519405</p>
                  </div> 
               </div>
            </div>
         </div>

         
         <div className={styles.block_two_tel}>
               <div className={styles.block_two_postfirst_tel}>
                  <div className={styles.postfirst_tel_img}>
                     <img src="./img/icon/017.gif" title="map" alt="map"></img>
                     <p>indicate our details (recipient):</p>
                  </div>
                  <div className={styles.postfirst_tel_txt}>
                     <p>Ward number 1 Kherson, logistics company Nova Poshta</p>
                  </div>
               </div>
               <div className={styles.block_two_postsecond_tel}>
                  <div className={styles.postsecond_tel_img}>
                     <img src="./img/icon/017.gif" title="map" alt="map"></img>
                     <p>recipient of the parcel:</p>
                  </div>
                  <div className={styles.postsecond_tel_txt}>
                     <p>Charitable Organization “Charitable Foundation” Invino Vera, tel. +380675519405</p>
                  </div> 
               </div>
            </div>


         <a href="https://novapost.com/en-de/departments?city=163000" title="map " target="_blank" rel="noreferrer"><div className={styles.nova_map}>
            <img src="./img/assets/ic_05.jpg" title="go to map" alt="go to map"></img>
         </div></a>
      </section>
   )
}

export default Novapost;