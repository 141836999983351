import React from 'react';
import styles from "./aboutus.module.scss"


function Aboutus ( ) {


   return (
      <section className={styles.root}>
         <div className={styles.about_us}>
            <p><em>Mykola Khalupenko</em> is the founder and director of our charity foundation. With over 30 years of experience in farming, he has been deeply involved in agricultural development. For many years, Mykola served as the chief executive of the Farmers' Association in the Kherson region, gaining an intimate understanding of the challenges faced by farmers and rural producers, especially during times of conflict.</p>
            <p>His unwavering commitment to the region and its people led him to remain steadfast throughout the war. Today, he continues to dedicate himself to the cause by establishing this charity foundation to support the farms that are the backbone of our community.</p>
         </div>
      </section>
   )
}

export default Aboutus;