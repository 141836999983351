import React from 'react';
// import styles from "./fotogallery.module.scss"
import './styles.css';

// модуль для свайпера (вертушки)
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import { Autoplay, Navigation } from 'swiper/modules';

function Fotogallery ( ) {


   return (
      <section className="slider">
         <div className="primermid-center">
            <div className="primermid-min">
               <Swiper 
                  slidesPerView={'auto'}
                  spaceBetween={30}
                  autoplay={{
                     delay: 5000,
                     disableOnInteraction: false,
                  }}
                  rewind={true}
                  navigation={true} 
                  modules={[Autoplay, Navigation]}     
                  className="mySwiper"
               >
                  <SwiperSlide>
                     <img className='image' src="./img/photo/foto_001.jpg" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_002.jpg" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_003.jpg" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_004.jpg" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_005.jpg" alt=""/>
                  </SwiperSlide>
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_006.jpg" alt=""/>
                  </SwiperSlide>     
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_007.jpg" alt=""/>
                  </SwiperSlide>  
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_008.jpg" alt=""/>
                  </SwiperSlide>    
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_009.jpg" alt=""/>
                  </SwiperSlide>  
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_010.jpg" alt=""/>
                  </SwiperSlide>  
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_011.jpg" alt=""/>
                  </SwiperSlide>  
                  <SwiperSlide>
                  <img className='image' src="./img/photo/foto_012.jpg" alt=""/>
                  </SwiperSlide>             
               </Swiper>
            </div>

            </div>





      </section>
   )
}

export default Fotogallery;