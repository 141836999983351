import React from 'react';
import styles from "./helpthing.module.scss"
import {motion} from 'framer-motion'


function Helpthing ( ) {

   const AnimationImgZn = {
      hidden: {
         x: -200,
         rotate: "-90deg", 
         opacity: 0,
      },
      visible: custom => ({
         x: 0,
         opacity: 1,
         rotate: "0deg", 
         transition: { delay: custom * 0.2, duration: 1, ease: "easeOut" },
      })
   }


   return (
      <motion.section 
         initial="hidden"
         whileInView="visible"
         viewport={{once: true}}
         className={styles.root}>
         <div className={styles.top_section} >
            <div className={styles.top_section_txt}>
               <p>Our charitable foundation is in urgent need of winemaking equipment, both new and used. We are specifically looking for the following items:</p>
            </div>
            <a href="https://agri-gator.com.ua/2024/05/02/vynorobnia-kurin-stvoriuie-blahodijnyj-fond-dlia-zberezhennia-vynohradnykiv-techdrinks-info/" title="news" target="_blank" rel="noreferrer">
               <div className={styles.top_section_but}>
               <p>news about us</p>
            </div></a> 
         </div>

         <div className={styles.img_section}>
            <motion.div className={styles.img_section_item} custom={3} variants={AnimationImgZn} >
               <div className={styles.item_img}>
                  <img src="./img/assets/ic_01.gif" title="Bottling lines" alt="Bottling lines"></img>
               </div>
               <div className={styles.item_txt}>
                  <p>Bottling lines for wine and champagne</p>
               </div>
            </motion.div>
            <motion.div className={styles.img_section_item} custom={4} variants={AnimationImgZn}  >
               <div className={styles.item_img}>
                  <img src="./img/assets/ic_02.gif" title="Pneumatic presses" alt="Pneumatic presses"></img>
               </div>
               <div className={styles.item_txt}>
                  <p>Pneumatic presses, grape crushers</p>
               </div>
            </motion.div>
            <motion.div className={styles.img_section_item} custom={5} variants={AnimationImgZn}  >
               <div className={styles.item_img}>
                  <img src="./img/assets/ic_03.gif" title="Storage tanks" alt="storage tanks"></img>
               </div>
               <div className={styles.item_txt}>
                  <p>Pumps, storage tanks, barrels, hoses</p>
               </div>
            </motion.div>
            <motion.div className={styles.img_section_item} custom={6} variants={AnimationImgZn}  >
               <div className={styles.item_img}>
                  <img src="./img/assets/ic_04.gif" title="Laboratory equipment" alt="Laboratory equipment"></img>
               </div>
               <div className={styles.item_txt}>
                  <p>Laboratory equipment</p>
               </div>
            </motion.div>
         </div>

         <div className={styles.down_section} id="mission">
            <p>Your support by donating any of these items would be invaluable to our operations and greatly appreciated.</p>
         </div>


      </motion.section>
   )
}

export default Helpthing;