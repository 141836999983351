import React from 'react';
import styles from "./footer.module.scss"
import { HashLink } from 'react-router-hash-link';



function Footer ( ) {


   return (
      <section className={styles.root}>

         <div className={styles.foot_center}>


            <div className={styles.foot_left}>

               <div className={styles.foot_left_menu}>
               <HashLink
                     to="/path#mission" 
                     scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><div className={styles.foot_left_punkt}>
                     <p>MISSION</p>
                  </div></HashLink>


                  <HashLink 
               to="/path#gallery" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><div className={styles.foot_left_punkt}>
                     <p>GALLERY</p>
                  </div></HashLink>

                   <HashLink 
               to="/path#datails" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><div className={styles.foot_left_punkt}>
                     <p>DONATION</p>
                  </div></HashLink>

                   <HashLink 
               to="/path#contact" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><div className={styles.foot_left_punkt}>
                     <p>CONTACTS</p>
                  </div></HashLink>
               </div>
               
               <div className={styles.foot_left_contacts}>
                  <div className={styles.contacts_top}>
                     <span>CHARITABLE ORGANIZATION «CHARITABLE FOUNDATION «INVINO VERA»</span>
                     <p>БЛАГОДІЙНА ОРГАНІЗАЦІЯ "БЛАГОДІЙНИЙ ФОНД "ІНВІНО ВЕРА"</p>
                     <p> 73035, Україна, Херсонський р-н, Херсонська обл., селище міського типу Антонівка, вулиця Сікалко, будинок, 14 </p>
                     <p>Код ЄДРПОУ: 45335086</p>
                  </div>
                  <div className={styles.contacts_down}>
                     <p>tel: +380675519405</p>
                  </div>
               </div>
            </div>

            <div className={styles.foot_right}>
               <a href="/" title="main">
                  <div className={styles.foot_logo}>
                     <img src="./img/logo/logo.gif" title="logo" alt="logo"></img>
                  </div>
               </a>
            </div>

         </div>




      </section>
   )
}

export default Footer;