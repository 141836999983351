import React, { useState } from 'react';
import styles from "./header.module.scss"
import {motion} from 'framer-motion'
import { HashLink } from 'react-router-hash-link';


function Header ( ) {

   const [postmenu, setPostmenu] = useState(false)

   const postMenuButton = () => {
      setPostmenu(!postmenu)
   }

   const textAnimationSpan = {
      hidden: {
         x: -100,
         opacity: 0,
      },
      visible: {
         x: 0,
         opacity: 1,
         transition: { delay: 1, duration: 0.5, ease: "easeOut" },
      }
   }
   const textAnimationBox = {
      hidden: {
         y: -100,
         opacity: 0,
      },
      visible: {
         y: 0,
         opacity: 1,
         transition: { delay: 1.5, duration: 0.5, ease: "easeOut" },
      }
   }
   const textAnimationTxt = {
      hidden: {
         y: 100,
         opacity: 0,
      },
      visible: {
         y: 0,
         opacity: 1,
         transition: { delay: 2, duration: 1, ease: "easeOut" },
         ease: 'leaner',
      }
   }

   return (
      <motion.section
         initial="hidden"
         whileInView="visible"
         viewport={{once: true}}
         className={styles.root}>
         <div className={styles.menu_top}>

            {/* - - левая часть меню */}
            <div className={styles.menu_top_left}>
                  <HashLink 
                     to="/path#mission" 
                     scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                     <div className={styles.top_left_punkt}>
                        <p>MISSION</p>
                     </div>
                  </HashLink>

               <HashLink 
               to="/path#gallery" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                  <div className={styles.top_left_punkt}>
                  <p>GALLERY</p>
               </div>
               </HashLink>

               <HashLink 
               to="/path#datails" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                  <div className={styles.top_left_punkt}>
                     <p>DONATION</p>
                  </div>
               </HashLink>

               <HashLink 
               to="/path#contact" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                  <div className={styles.top_left_punkt}>
                  <p>CONTACTS</p>
               </div></HashLink>
            </div>

            {/* - - правая часть меню */}
            <div className={styles.menu_top_right}>
               <a href="tel:+380675519405" title="call" target="_blank" rel="noreferrer">
                  <div className={styles.top_right_phone}>
                     <img src="./img/icon/005.gif" title="call" alt="call"></img>
                     <p>+38 067 551 94 05</p>
                  </div>
               </a> 
               <div className={styles.top_right_men} onClick={() => postMenuButton()}>
                  <img src="./img/icon/menu.png" title="menu" alt="menu"></img>
               </div>
            </div>

            {/* - - выпадающее меню */}
            {postmenu && (
               <div className={styles.menu_top_slider}>
                  <div className={styles.slider_call} onClick={() => postMenuButton()} >
                     
                     <a href="tel:+380675519405" title="call" target="_blank" rel="noreferrer" className={styles.menutel}>
                        <img src="./img/icon/005.gif" title="call" alt="call"></img>
                     </a>

                     <a href="mailto:cf@invino-vera.com" title="send email" target="_blank" rel="noreferrer">
                        <img src="./img/icon/002.gif" title="send email" alt="send email"></img>
                     </a>

                     <a href="https://t.me/sommelierorangewines" title="Telegram" target="_blank" rel="noreferrer">
                        <img src="./img/icon/003.gif" title="telegram" alt="telegram"></img>
                     </a>
                     
                     <a href="https://wa.me/+380675519405" title="WhatsApp" target="_blank" rel="noreferrer">
                        <img src="./img/icon/004.gif" title="watsApp" alt="watsApp"></img>
                     </a>                     

                  </div>
                  <div className={styles.slider_soc} onClick={() => postMenuButton()} >

                     <a href="https://www.instagram.com/p/C6LlwlANgXz/?igsh=MTI2ZnBhcTU5dGJqdg==" title="Instagram" target="_blank" rel="noreferrer">
                        <img img src="./img/icon/014.gif" title="instagram" alt="instagram"></img>
                     </a>

                     <a href="https://www.youtube.com/@InvinoVira" title="youtube" target="_blank" rel="noreferrer">
                        <img src="./img/icon/015.gif" title="youtube" alt="youtube"></img>
                     </a>

                     <a href="https://www.facebook.com/share/p/CrFmUxZj4LpP3SeM/" title="facebook" target="_blank" rel="noreferrer">
                        <img src="./img/icon/012.gif" title="facebook" alt="facebook"></img>
                     </a>                   
                     
                  </div>
               </div>
            )}

         </div>

         {/* - - выплывающее левое меню */}
         {postmenu && (
            <div className={styles.menutel_px} onClick={() => postMenuButton()} >
               <div className={styles.menutel_px_pole}>
               <HashLink 
                     to="/path#mission" 
                     scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                        <div className={styles.menutel_px_punkt}>
                     <p>MISSION</p>
                  </div>
                  </HashLink>
                  <HashLink 
               to="/path#gallery" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                  <div className={styles.menutel_px_punkt}>
                     <p>GALLERY</p>
                  </div></HashLink>

                  <HashLink 
               to="/path#datails" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                  <div className={styles.menutel_px_punkt}>
                     <p>DONATION</p>
                  </div></HashLink>
                  <HashLink 
               to="/path#contact" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}>
                  <div className={styles.menutel_px_punkt}>
                     <p>CONTACTS</p>
                  </div></HashLink>
                  <a href="tel:+380675519405" title="call" target="_blank" rel="noreferrer">
                     <div className={styles.menutel_px_tel}>
                        <img src="./img/icon/005.gif" title="call" alt="call"></img>
                        <p>+38 067 551 94 05</p>
                     </div>
                  </a> 
               </div>
               
            </div>
         )}

         {/* - - логотип */}
         <a href="/" title="maine"><div className={styles.logo}>
            <img src="./img/logo/logo.gif" title="logo" alt="logo"></img>
         </div></a>

         {/* - - подпись*/}
         <div className={styles.podpis}> 
            <div className={styles.podpis_span}>
               <motion.p variants={textAnimationSpan}>Charitable Organization “Charitable Foundation” Invino Vera </motion.p>
            </div>
            <motion.div variants={textAnimationBox} className={styles.podpis_txt}>
               <motion.p variants={textAnimationTxt}>Our foundation operates in Ukraine's Kherson region, adjacent to Crimea, with a mission to restore vineyards and wineries devastated by conflict. We are committed to rebuilding the heritage of viticulture by aiding in the recovery of damaged estates and supporting the families of vintners whose lives and livelihoods have been affected by missile strikes and other acts of destruction.</motion.p>
            </motion.div>
         </div>

      </motion.section>
   )
}

export default Header;
