import React from 'react';
import styles from "./social.module.scss"
import Fotogallery from '../Fotogallery/Fotogallery';


function Social ( ) {


   return (
      <section className={styles.root}>

         <div className={styles.social_span}>
            <p>Any help you provide will be indispensable to us.</p>
         </div>

         <Fotogallery />

         <div className={styles.social_block} id="gallery">
            <div className={styles.social_center}>
               <div className={styles.social_block_left}>
                  <a href="https://www.instagram.com/p/C6LlwlANgXz/?igsh=MTI2ZnBhcTU5dGJqdg==">
                     <div className={styles.left_soc}>
                        <img src="./img/icon/014.gif" title="instagram" alt="instagram"></img>
                        <p>instagram</p>
                     </div>
                  </a>
                  <a href="https://www.facebook.com/share/p/CrFmUxZj4LpP3SeM/">
                     <div className={styles.left_soc}>
                        <img src="./img/icon/012.gif" title="facebook" alt="facebook"></img>
                        <p>facebook</p>
                     </div>
                  </a>
                  <a href="https://www.youtube.com/@InvinoVira">
                     <div className={styles.left_soc  + ' ' + styles.you} >
                        <img src="./img/icon/015.gif" title="yuotube" alt="yuotube"></img>
                        <p>youtube</p>
                     </div>
                  </a>
               </div>
               <div className={styles.social_block_right}>
                  <a href="https://youtube.com/shorts/o-k7284fvh0?si=9V2s2Z-dPsoZ5f7R">
                     <div className={styles.social_block_right_gray}>
                        <img src="./img/fone/f_08.jpg" title="yuotube" alt="yuotube"></img>
                     </div>
                     
                  </a>
               </div>
            </div>
         </div>

      </section>
   )
}

export default Social;