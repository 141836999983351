
import './App.css';

import Helpneed from './Helpneed/Helpneed';
import Aboutus from './Aboutus/Aboutus';
import Helpthing from './Helpthing/Helpthing';
import Header from './Header/Header';
import Helpfoto from './Helpfoto/Helpfoto';
import Novapost from './Novapost/Novapost';
import Social from './Social/Social';
import Datails from './Datailsdonation/Datails';
import Thankyou from './Thankyou/Thankyou';
import Footer from './Footer/Footer';


function App() {
  return (
    <div className="App">


      <Header />
      <Helpneed />
      <Aboutus />
      <Helpthing />
      <Helpfoto />
      <Novapost />
      <Social />
      <Datails />
      <Thankyou />
      <Footer />

    </div>
  );
}

export default App;
