import React from 'react';
import styles from "./helpfoto.module.scss"
import { HashLink } from 'react-router-hash-link';
import {motion} from 'framer-motion'

function Helpfoto ( ) {

   const textPhAnimationLeft = {
      hidden: {
         x: -200,
         opacity: 0,
      },
      visible: custom => ({
         x: 0,
         opacity: 1,
         transition: { delay: custom * 0.2, duration: 1, ease: "easeOut" },
      })
   }
   const textPhAnimationRight = {
      hidden: {
         x: -200,
         opacity: 0,
      },
      visible: custom => ({
         x: 0,
         opacity: 1,
         transition: { delay: custom * 0.2, duration: 1, ease: "easeOut" },
      })
   }

   return (
      <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{once: true}}
      className={styles.root}
   >
         <div className={styles.sect_black_first}>
            <div className={styles.black_first_txt} >
               <motion.p variants={textPhAnimationLeft} custom={4}>Even the smallest contributions can make a significant difference. We are in need of pruning shears, scissors, wires, and vineyard posts to maintain the vineyards. Additionally, we require treatments to protect the plants and feed to sustain the livestock that supports our community.</motion.p>
            </div>
         </div>

         <div className={styles.sect_foto_first}>
            <img src="./img/fone/f_04.jpg" title="invino vera" alt="invino vera"></img>
         </div>

         <div className={styles.sect_black_second}>
            <div className={styles.black_second_txt}>
               <motion.p variants={textPhAnimationRight} custom={5}>Your support can help us rebuild and continue the proud tradition of winemaking in Kherson. Please consider donating equipment, supplies, or funds to assist us in this challenging time.</motion.p>
            </div>
         </div>

         {/* - - кнопка доната */}
         <div className={styles.help_center_phone}>
         <HashLink 
               to="/path#datails" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><div className={styles.help_but}>
               <p>donation</p>
            </div></HashLink>
         </div>

         <div className={styles.sect_black_third}>
            <div className={styles.black_third_txt}>
               <motion.p variants={textPhAnimationRight} custom={6}>For more information on how you can help, please.</motion.p>
            </div>
         </div>

         <div className={styles.sect_foto_second}>
            <img src="./img/fone/f_05.jpg" title="invino vera" alt="invino vera"></img>
         </div>

         {/* - - кнопка доната */}
         <div className={styles.help_center}>
         <HashLink 
               to="/path#datails" 
               scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'end' })}><div className={styles.help_but}>
               <p>donation</p>
            </div></HashLink>
         </div>

      </motion.section>
   )
}

export default Helpfoto;