import React from 'react';
import styles from "./thankyou.module.scss"
import {motion} from 'framer-motion'


function Thankyou ( ) {


   const thankAnimation = {
      hidden: {
         y: -300,
         opacity: 0,
      },
      visible: custom => ({
         y: 0,
         opacity: 1,
         transition: { delay: custom * 0.2, duration: 1, ease: "easeOut" },
      })
   }

   return (
      <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{once: true}}
      className={styles.root}
   >
         <div className={styles.thank_center}>
            <div className={styles.thank_span}>
               <motion.p variants={thankAnimation} custom={4}>THANK YOU</motion.p >
               <motion.p variants={thankAnimation} custom={6}>FOR YOUR</motion.p>
               <motion.p variants={thankAnimation} custom={8}>ATTENTION</motion.p>
            </div>
            
         </div>
      </motion.section>
   )
}

export default Thankyou;