import React, { useState } from 'react';
import styles from "./datails.module.scss"
import { motion, AnimatePresence } from 'framer-motion';


function Datails ( ) {

   const [isUsd, setUsd] = useState(true)
   const [isEur, setEur] = useState(false)
   const [isUah, setUah] = useState(false)

   const openUsd = () => {
      setUsd(!isUsd)
      setEur(false)
      setUah(false)
   }

   const openEur = () => {
      setUsd(false)
      setEur(!isEur)
      setUah(false)
   }

   const openUah = () => {
      setUsd(false)
      setEur(false)
      setUah(!isUah)
   }

   return (
      <div className={styles.root} id="datails">
         <div className={styles.datails_span}>
            <p>Details for donations</p>
         </div>

         {/* - - - - - - - - -  */}
         <div className={styles.datails_valUSD}>

            <div className={styles.datails_valUSD_span} onClick={() => openUsd()}>
               <div className={styles.valUSD_span_center}>
                  <div className={styles.valUSD_span_left}>
                     <p>+ USD</p>
                     <img src="./img/icon/018.png" title="open" alt="open"></img>
                  </div>
                  <div className={styles.valUSD_span_right}>
                     <a href="https://docs.google.com/document/d/18KxxNuxuSQ_Swvz0d_G0PcHdgr2MSezd/edit?usp=sharing&ouid=102653634093752528615&rtpof=true&sd=true" title="download" target="_blank" rel="noreferrer" ><p>download</p> </a>
                  </div>
               </div>
            </div>

            <AnimatePresence>

            {isUsd && (
               <motion.div
                  initial = {{height: 0, opacity: 0}}
                  animate = {{height: 'auto', opacity: 1}}
                  exit = {{height: 0, opacity: 0}}
                  style = {{overflow: 'hidden'}}
                  transition = {{ delay: 0, duration: 0.5, ease: "easeOut" }}
                  className={styles.datails_valUSD_txt} >
               <div className={styles.valUSD_span_center}>
                  <p><em>Назва БФ/company Name:</em> Благодійний фонд ІНВІНО ВІРА БО</p>
                  <p><em>«Charitable foundation «Invino Vera»</em></p>
                  <p><em>IBAN Code:</em> UA883052990000026004041105435</p>
                  <p><em>Назва Банку/Name of the bank:</em> JSK CB «PRIVATBANK», 1D Hrushevskoho str., Kyiv, 01001, Ukraine</p>
                  <p><em>Requistes swiftCode:</em> PBANUA2X</p>
                  <p><em>Адреса підприємства/Company address:</em> UA 73000 Херсонська область Херсон смт Антонівка вул. Сікалко б. 14</p>

                  <span>Банки кореспонденти/Correspondent banks</span>

                  <p><em>Рахунок в банку кореспонденті/Account in the correspondent bank:</em> 001-1-000080</p>
                  <p><em>SWIFT Code банку-кореспондента/SWIFT Code of the correspondent bank:</em> CHASUS33</p>
                  <p><em>Банк кореспондент/Сorrespondent bank:</em> JP Morgan Chase Bank, New York, USA</p>
                  <p><em>Рахунок в банку кореспонденті/Account in the correspondent bank:</em> 890-0085-754</p>
                  <p><em>SWIFT Code банку-кореспондента/SWIFT Code of the correspondent bank: </em> IRVT US 3N</p>
                  <p><em>Банк кореспондент/Correspondent bank: </em> The Bank of New York Mellon, New York, USA</p>
                  <p><em>Рахунок в банку кореспонденті/Account in the correspondent bank: </em> 36445343</p>
                  <p><em>SWIFT Code банку-кореспондента/SWIFT Code of the correspondent bank:</em> CITI US 33</p>
                  <p><em>Банк кореспондент/Correspondent bank:</em> Citibank N.A., New York, USA</p>
                  <p><em></em> </p>
                  <p><em></em> </p>
                  <p><em></em> </p>
               </div>
            </motion.div>)}

            </AnimatePresence>

         </div>

         <div className={styles.datails_valUSD} onClick={() => openEur()}>

            <div className={styles.datails_valUSD_span}>
               <div className={styles.valUSD_span_center}>
                  <div className={styles.valUSD_span_left}>
                     <p>+ EUR</p>
                     <img src="./img/icon/018.png" title="open" alt="open"></img>
                  </div>
                  <div className={styles.valUSD_span_right}>
                     <a href="https://docs.google.com/document/d/18KxxNuxuSQ_Swvz0d_G0PcHdgr2MSezd/edit?usp=sharing&ouid=102653634093752528615&rtpof=true&sd=true" title="download" target="_blank" rel="noreferrer" ><p>download</p> </a>
                  </div>
               </div>
            </div>


              <AnimatePresence >
              {isEur && (
               <motion.div 
               initial = {{height: 0, opacity: 0}}
               animate = {{height: 'auto', opacity: 1}}
               exit = {{height: 0, opacity: 0}}
               style = {{overflow: 'hidden'}}
               transition = {{ delay: 0, duration: 0.5, ease: "easeOut" }}
               className={styles.datails_valUSD_txt}>
               <div className={styles.valUSD_span_center}>
                  
                  <p><em>Назва БФ/company Name:</em> Благодійний фонд ІНВІНО ВІРА БО</p>
                  <p><em>«Charitable foundation «Invino Vera»</em> </p>
                  <p><em>IBAN Code:</em> UA263052990000026004021102726</p>
                  <p><em>Назва Банку/Name of the bank:</em> JSK CB «PRIVATBANK», 1D Hrushevskoho str., Kyiv, 01001, Ukraine</p>
                  <p><em>Requistes swiftCode:</em> PBANUA2X</p>
                  <p><em>Адреса підприємства/Company address: </em> UA 73000 Херсонська область Херсон смт Антонівка вул. Сікалко б. 14</p>

                  <span>Банки кореспонденти/Correspondent banks</span>

                  <p><em>Рахунок в банку кореспонденті/Account in the correspondent bank:</em> 4008867700401</p>
                  <p><em>SWIFT Code банку-кореспондента/SWIFT Code of the correspondent bank:</em> COBADEFF</p>
                  <p><em>Банк кореспондент/Correspondent bank:</em> Commerzbank AG, Frankfurt am Main, Germany</p>
                  <p><em>Рахунок в банку кореспонденті/Account in the correspondent bank:</em> 6231605145</p>
                  <p><em>SWIFT Code банку-кореспондента/SWIFT Code of the correspondent bank: </em> CHASDEFX</p>
                  <p><em>Банк кореспондент/Correspondent bank: </em> J.P.Morgan AG, Frankfurt am Main, Germany</p>
                  <p><em>Рахунок в банку кореспонденті/Account in the correspondent bank:</em> 5184099710, IBAN DE395003303005184099710</p>
                  <p><em>SWIFT Code банку-кореспондента/SWIFT Code of the correspondent bank: </em> IRVTDEFX</p>
                  <p><em>Банк кореспондент/Correspondent bank:</em> THE BANK OF NEW YORK MELLON, Frankfurt am Main</p>
                  <p><em>Рахунок в банку кореспонденті/Account in the correspondent bank:</em> 0042997188, IBAN IE96CITI99005142997188</p>
                  <p><em>SWIFT Code банку-кореспондента/SWIFT Code of the correspondent bank:</em> CITIIE2X</p>
                  <p><em>Банк кореспондент/Correspondent bank:</em> CITIBANK EUROPE PLC</p>
               </div>
            </motion.div>)}
               </AnimatePresence> 

         </div>


         <div className={styles.datails_valUSD} onClick={() => openUah()}>  

            <div className={styles.datails_valUSD_span}>
               <div className={styles.valUSD_span_center}>
                  <div className={styles.valUSD_span_left}>
                     <p>+ UAH</p>
                     <img src="./img/icon/018.png" title="open" alt="open"></img>
                  </div>
                  <div className={styles.valUSD_span_right}>
                     <a href="https://docs.google.com/document/d/18KxxNuxuSQ_Swvz0d_G0PcHdgr2MSezd/edit?usp=sharing&ouid=102653634093752528615&rtpof=true&sd=true" title="download" target="_blank" rel="noreferrer" ><p>download</p> </a>
                  </div>
               </div>
            </div>
         <AnimatePresence >
            {isUah && (
               <motion.div 
               initial = {{height: 0, opacity: 0}}
               animate = {{height: 'auto', opacity: 1}}
               exit = {{height: 0, opacity: 0}}
               style = {{overflow: 'hidden'}}
               transition = {{ delay: 0, duration: 0.3, ease: "easeOut" }}
               className={styles.datails_valUSD_txt}>
               <div className={styles.valUSD_span_center}>
                  
                  <p><em>Назва БФ/company Name: </em> Благодійний фонд ІНВІНО ВІРА БО</p>
                  <p><em>«Charitable foundation «Invino Vera»</em> </p>
                  <p><em>ЄДРПОУ: </em> 45335086</p>
                  <p><em>АТ КБ «ПРИВАТБАНК» (ЄДРПОУ банку 14360570, код банку 305299)</em> </p>
                  <p><em>Рахунок:</em> UA233052990000026001021102785</p>
                  <p><em></em> </p>

               </div>
            </motion.div>)}
            </AnimatePresence>
         </div>


      </div>
   )
}

export default Datails;